import axios from "axios"
import { Incident, AddIncidentNote, ResolveIncident } from "../Interfaces";

class IncidentService {
  private baseUrl = process.env.REACT_APP_API_URL + "/incident";
  // CREATE ==============================================
  public addNote = async (incidentInfo: AddIncidentNote,) => {
    try {
      if (!incidentInfo || !incidentInfo.note || !incidentInfo.userId) throw new Error("Missing incident info")
      const response = await axios.post(
        `${this.baseUrl}/note`,
        incidentInfo,
      );
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }

  // READ ==============================================
  public getOne = async (incidentId: number) => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/${incidentId}`
      );
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        // Do something with this error...
      } else {
        console.error(error);
      }
    }
  }

  public getAllByLocation = async ({ locationId, unresolvedOnly }: { locationId: Number, unresolvedOnly: boolean }): Promise<Incident[]> => {
    try {
      const response = await axios.get(
        `${this.baseUrl}/by-location/${locationId}`
      );
      if (!response.data) {
        console.error("No data received from incident service");
        return [];
      }
      const incidents = response.data.incidents || [];
      return unresolvedOnly ? incidents.filter((incident: Incident) => !incident.resolvedAt) : incidents;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error in getAllByLocation:", error.response);
        throw new Error(error.response?.statusText || "Failed to fetch incidents");
      } else {
        console.error("Non-axios error in getAllByLocation:", error);
        throw error instanceof Error ? error : new Error("An unknown error occurred");
      }
    }
  }

  public getAll = async (): Promise<Incident[]> => {
    try {
      const response = await axios.get(
        `${this.baseUrl}`
      );
      console.log(response.data)
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw new Error("Failed to fetch incidents");
      } else {
        console.error(error);
        throw new Error("An unexpected error occurred");
      }
    }
  }

  // UPDATE ==============================================
  public resolve = async (incidentInfo: ResolveIncident) => {
    try {
      if (!incidentInfo || !incidentInfo.incidentId) throw new Error("Missing incident info")
      const response = await axios.put(
        `${this.baseUrl}/${incidentInfo.incidentId}/resolve`,
        { resolved: incidentInfo.resolved, resolvedAt: incidentInfo.resolvedAt }
      );
      return response.data
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response);
        throw Error(error.response?.statusText)
      } else {
        throw Error(error)
      }
    }
  }
}

export const incidentService = new IncidentService()