import { Link, useLocation, useParams } from "react-router-dom"
import { Location, MarkerLocation, Walk } from "../../../../Interfaces"
import Map from "../../../../components/maps/Map"
import { useEffect, useRef, useState, StrictMode } from "react"
import { locationService } from "../../../../services/locationService"
import SaveButtonWithStatus from "../../../../components/general/SaveButtonWithStatus"
import { FaRedo, FaSave, FaUndo } from "react-icons/fa"
import ConfirmationModal from "../../../../components/modals/ConfirmationModal"
import NewWalkModal from "../../../../components/modals/NewWalkModal"
import { walkService } from "../../../../services/walkService"
import PopupModal from "../../../../components/modals/PopupModal"
import LocationUsersTable from "../../../../components/tables/LocationUsersTable"
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

let trashedMarkers: MarkerLocation[] = []

function SortableItem({ marker, onEditRange }: { marker: MarkerLocation; onEditRange: (marker: MarkerLocation) => void }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: marker.label.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex items-center justify-between bg-secondary p-2 rounded text-white hover:bg-secondary-light group"
    >
      <div
        className={`flex-1 ${marker.unitNumberRange ? 'cursor-pointer' : ''} pr-2`}
        onClick={marker.unitNumberRange ? () => onEditRange(marker) : undefined}
      >
        <span>{marker.unitNumberRange || `Point ${marker.label}`}</span>
      </div>
      <div
        {...attributes}
        {...listeners}
        className="cursor-grab active:cursor-grabbing"
      >
        <span className="bg-primary px-2 py-1 rounded text-sm">{marker.label}</span>
      </div>
    </div>
  );
}

export default function CreatWalks() {
  const location = useLocation()
  const { id } = useParams()
  const [currentLocation, setCurrentLocation] = useState<Location | null>(null)
  const [zoom, setZoom] = useState<number>(18)
  const [savingStatus, setSavingStatus] = useState<null | string>(null)
  const [savingWalkStatus, setSavingWalkStatus] = useState<null | string>(null)
  const [deleteStatus, setDeleteStatus] = useState<null | string>(null)
  const [markers, setMarkers] = useState<MarkerLocation[]>([])
  const [walks, setWalks] = useState<Walk[]>([])
  const [currentWalk, setCurrentWalk] = useState<null | Walk>(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false)
  const [showNewWalkModal, setShowNewWalkModal] = useState<boolean>(false)
  const [walkName, setWalkName] = useState<string>("")
  const [walkDescription, setWalkDescription] = useState<string>("")
  const [showPopupModal, setShowPopupModal] = useState<boolean>(false)
  const [showNumberRangeModal, setShowNumberRangeModal] = useState(false)
  const [numberRangeInput, setNumberRangeInput] = useState("")
  const [selectedMarker, setSelectedMarker] = useState<MarkerLocation | null>(null)
  const coordinates = useRef<google.maps.LatLngLiteral>({ lat: 0, lng: 0 })
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(0)
  const [isProcessingMarker, setIsProcessingMarker] = useState(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchLocation = async () => {
      if (!id) return
      try {
        const locationData = await locationService.getOne(Number(id))
        if (locationData) {
          setCurrentLocation(locationData)
          coordinates.current = {
            lat: locationData.latitude,
            lng: locationData.longitude
          }
          if (locationData.zoomLevel) {
            setZoom(locationData.zoomLevel)
          }
        }
      } catch (error) {
        console.error("Error fetching location:", error)
      }
    }

    // If we have location state, use it; otherwise fetch the location
    if (location.state?.currentLocation) {
      const locationData = location.state.currentLocation
      setCurrentLocation(locationData)
      coordinates.current = {
        lat: locationData.latitude,
        lng: locationData.longitude
      }
      if (locationData.zoomLevel) {
        setZoom(locationData.zoomLevel)
      }
    } else {
      fetchLocation()
    }
  }, [id, location.state])

  useEffect(() => {
    if (currentLocation?.id) {
      fetchWalks()
    }
  }, [currentLocation])

  useEffect(() => {
    const currentMarker = markers[currentMarkerIndex]
    if (!currentMarker || isProcessingMarker || showNumberRangeModal) return

    // Don't reopen modal if the marker already has a unit range or is completed
    if ((!currentMarker.lat && !currentMarker.lng) && !currentMarker.isCompleted && !currentMarker.unitNumberRange) {
      setIsProcessingMarker(true)
      setShowNumberRangeModal(true)
    }
  }, [currentMarkerIndex, markers, isProcessingMarker, showNumberRangeModal])

  useEffect(() => {
    console.log("Modal state changed:", { showNumberRangeModal, isProcessingMarker })
  }, [showNumberRangeModal, isProcessingMarker])

  const fetchWalks = async () => {
    const fetchedWalks = await walkService.getAll(currentLocation?.id || null)
    console.log("Raw API response from /api/walk/all:", JSON.stringify(fetchedWalks, null, 2))
    fetchedWalks?.forEach((walk: Walk, index: number) => {
      console.log(`Walk ${index + 1} (${walk.name}):`, JSON.stringify(walk, null, 2))
      console.log(`Walk ${index + 1} markers:`, JSON.stringify(walk.markers, null, 2))
    })
    const filteredWalks = fetchedWalks?.filter((walk: Walk) => walk.deletedAt === null)
    setWalks(filteredWalks || [])
  }

  const handleSaveZoom = async () => {
    if (!currentLocation) return
    setSavingStatus("Saving...")
    try {
      const locationToUpdate: Location = {
        ...currentLocation,
        zoomLevel: zoom
      }
      const status = await locationService.update(locationToUpdate)
      if (status) {
        setSavingStatus("Saved!")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      } else {
        setSavingStatus("Error")
        setTimeout(() => {
          setSavingStatus(null)
        }, 1500)
      }
    } catch (error) {
      setSavingStatus("Error")
      setTimeout(() => {
        setSavingStatus(null)
      }, 1500)
    }
  }

  const handleSaveWalk = async () => {
    trashedMarkers = []
    // If user is editing a walk, create a new copy w/ new markers and delete (deleteAt flag) old walk
    if (currentWalk) {
      const newMarkers = markers.map((marker) => {
        const { id, ...markerWithoutId } = marker
        return {
          ...markerWithoutId,
          walkId: currentWalk.id,
          hasUnitRange: !!marker.unitNumberRange
        }
      })
      console.log("Saving edited walk markers:", newMarkers)
      if (!currentLocation?.id) return
      const walkInfo = { name: currentWalk.name, description: currentWalk.description, locationId: currentLocation.id, markers: newMarkers }
      setSavingWalkStatus("Saving...")

      let status: Walk | null = null
      try {
        status = await walkService.create(walkInfo)
        await walkService.delete(currentWalk.id)
        if (status) {
          setSavingWalkStatus("Saved!") // triggers data fetch
          setTimeout(async () => {
            fetchWalks()
            setSavingWalkStatus(null)
            setShowConfirmationModal(false)
            setMarkers([])
          }, 2000)
        }
      }
      catch (error) {
        // show error button or show error fallback page?
        setSavingWalkStatus("Error")
        setTimeout(() => {
          setSavingWalkStatus(null)
        }, 1500)
        // showBoundary(error)
      }
      // For new walks, create new walk
    } else {
      if (!currentLocation?.id || !walkName) return
      const markersToSave = markers.map(marker => ({
        ...marker,
        hasUnitRange: !!marker.unitNumberRange
      }))
      console.log("Saving new walk markers:", markersToSave)
      const walkInfo = {
        name: walkName,
        description: walkDescription,
        locationId: currentLocation.id,
        markers: markersToSave
      }
      setSavingWalkStatus("Saving...")
      let status: Walk | null = null
      try {
        status = await walkService.create(walkInfo)
        if (status) {
          setSavingWalkStatus("Saved!") // triggers data fetch
          setTimeout(async () => {
            fetchWalks()
            setSavingWalkStatus(null)
            setShowNewWalkModal(false)
            setMarkers([])
          }, 2000)
        }
      } catch (error) {
        // show error button or show error fallback page?
        setSavingWalkStatus("Error")
        setTimeout(() => {
          setSavingWalkStatus(null)
        }, 1500)
        // showBoundary(error)
      }
    }
  }

  const handleUndo = () => {
    if (!markers.length) return
    const trashedMarker = markers.pop()
    if (!trashedMarker) return
    trashedMarkers.push(trashedMarker)
    setMarkers((current) => [...current])
  }

  const handleRedo = () => {
    if (!trashedMarkers.length) return
    const restoredMarker = trashedMarkers.pop()
    markers.push(restoredMarker!!)
    setMarkers((current) => [...current])
  }

  const handleLoadWalk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    trashedMarkers = []
    if (e.currentTarget.id === "new walk") {
      setCurrentWalk(null)
      setMarkers([])
      setCurrentMarkerIndex(0)
      return
    }
    const walkId = Number(e.currentTarget.id)
    const walk = walks.find((walk) => walk.id === walkId)
    if (!walk) return
    console.log("Loading walk:", JSON.stringify(walk, null, 2))
    setCurrentWalk(walk)
    const sortedMarkers = walk.markers?.sort((a, b) => a.label - b.label)
    console.log("Loading walk markers:", JSON.stringify(sortedMarkers, null, 2))
    setMarkers(sortedMarkers || [])
    setCurrentMarkerIndex(0)
  }

  const handleMarkers = (markers: MarkerLocation[]) => {
    trashedMarkers = []
    console.log("Adding new markers:", markers)
    setMarkers((prev) => [...prev, ...markers])
    setCurrentMarkerIndex(prev => prev + markers.length)
  }

  const handleModal = (e: { currentTarget: { name: string } }) => {
    if (currentWalk) {
      if (e.currentTarget.name === "delete") {
        setShowDeleteConfirmationModal(true)
        return
      }
      setShowConfirmationModal(true)
    } else {
      setWalkName("")
      setWalkDescription("")
      setShowNewWalkModal(true)
    }
  }

  const handleDeleteWalk = async () => {
    if (!currentWalk) return
    setDeleteStatus("Deleting...")
    try {
      const status = await walkService.delete(currentWalk.id)
      if (status) {
        const updatedWalks = walks.filter((walk) => walk.id !== currentWalk.id)
        setWalks(updatedWalks)
        setDeleteStatus("Deleted!")
        setTimeout(() => {
          setDeleteStatus(null)
          setShowDeleteConfirmationModal(false)
          setCurrentWalk(null)
          setMarkers([])
        }, 2000)
      } else {
        setDeleteStatus("Error")
        setTimeout(() => {
          setDeleteStatus(null)
        }, 1500)
      }
    } catch (error) {
      // show error button or show error fallback page?
      setDeleteStatus("Error")
      setTimeout(() => {
        setDeleteStatus(null)
      }, 1500)
      // showBoundary(error)
    }
  }

  const handleWalkName = (e: { target: { value: string } }) => {
    setWalkName(e.target.value)
  }

  const handleWalkDescription = (e: { target: { value: string } }) => {
    setWalkDescription(e.target.value)
  }

  const handleLocationAdjustment = (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => {
    coordinates.current.lat = Number(latLng.lat)
    coordinates.current.lng = Number(latLng.lng)
  }

  const handleEditRange = (marker: MarkerLocation) => {
    console.log("Opening modal for editing marker:", marker)
    setSelectedMarker(marker);
    setNumberRangeInput(marker.unitNumberRange || "");
    setShowNumberRangeModal(true);
  };

  const handleNumberRangeInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Only allow numbers, commas, dashes, and spaces
    const sanitizedValue = e.target.value.replace(/[^0-9,\s-]/g, '');
    setNumberRangeInput(sanitizedValue);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setMarkers((items) => {
        const oldIndex = items.findIndex(item => item.label.toString() === active.id);
        const newIndex = items.findIndex(item => item.label.toString() === over.id);

        const newItems = arrayMove(items, oldIndex, newIndex);
        return newItems.map((item, index) => ({
          ...item,
          label: index + 1
        }));
      });
    }
  };

  // Don't render until we have location data
  if (!currentLocation) {
    return <div>Loading...</div>
  }

  return (
    <div className="w-full min-h-full flex justify-center">
      <div className="w-full flex flex-col">
        <section className="bg-secondary-extra-light p-2 md:rounded-lg mb-2 shadow-lg h-full">
          <div className="bg-primary-dark h-full flex flex-col">
            <div className=" text-white rounded-lg p-4 flex justify-between">
              <div>
                <h3 className="">{currentLocation.name}</h3>
                <h4 className="">{currentLocation.address}</h4>
                <h4 className="">{`${currentLocation.city}, ${currentLocation.state} ${currentLocation.zipcode}`}</h4>
              </div>
              <div>
                <div className="flex justify-end p-2">
                  <button className="rounded-lg p-2 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light" onClick={() => setShowPopupModal(true)}>
                    Employees
                  </button>
                  <Link to={`../locations/${currentLocation.id}`} className="rounded-lg p-2 ml-3 text-white text-center bg-primary border-sedondary hover:border-2 hover:border-primary-light">
                    Walk Reports
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex flex-grow">
              <div className="shadow-lg  bg-secondary-extra-light w-full flex-grow relative">
                <Map zoom={zoom} setZoom={setZoom} name={currentLocation.name} coordinates={coordinates.current} handleLocationAdjustment={handleLocationAdjustment} setMarkers={handleMarkers} markers={markers} />
                <SaveButtonWithStatus saveStatus={savingStatus} handleSave={handleSaveZoom} text="Save Zoom Level" className="absolute w-40 bottom-1 left-1 shadow-right-lg" />
              </div>
              <div className="bg-secondary-dark w-52 flex flex-col">
                <div className="flex w-full justify-between">
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center" aria-details="undo button" onClick={handleUndo}>
                    <FaUndo />
                  </button>
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center disabled:bg-slate-400" aria-details="save button" onClick={handleModal} disabled={!markers.length}>
                    <FaSave />
                  </button>
                  <button className="rounded-lg m-3 p-2 w-1/4 text-white text-center bg-secondary border-sedondary hover:border-2 hover:border-secondary-light flex justify-center" aria-details="redo button" onClick={handleRedo}>
                    <FaRedo />
                  </button>
                </div>

                <div className="flex-1 overflow-y-auto">
                  <h3 className="text-white text-lg font-semibold px-4 mb-2">Markers</h3>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={markers.map(m => m.label.toString())}
                      strategy={verticalListSortingStrategy}
                    >
                      <div className="space-y-2 px-2">
                        {markers.map((marker) => (
                          <SortableItem key={marker.label} marker={marker} onEditRange={handleEditRange} />
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                </div>

                <div className="mt-auto">
                  <h3 className="text-white px-4 mb-2">Storage Walks</h3>
                  <hr className="outline-none border-none h-px bg-secondary w-full my-2"></hr>
                  {walks.map((walk) => (
                    <button className={`p-2 w-full flex justify-center border-secondary hover:border-secondary-light ${currentWalk?.id === walk.id && "bg-secondary "}`} key={walk.id} onClick={handleLoadWalk} id={walk.id.toString()}>
                      <div className="flex w-3/4 items-center">
                        <p className="text-white">{walk.name}</p>
                      </div>
                    </button>
                  ))}
                  <button className={`p-2 flex justify-center border-secondary hover:border-secondary-light w-full ${!currentWalk && "bg-secondary"}`} onClick={handleLoadWalk} id="new walk">
                    <div className="flex w-3/4">
                      <p className="text-white">New Walk</p>
                    </div>
                  </button>
                  <div className="w-full flex flex-col items-center pb-2">
                    <button
                      onClick={() => setShowNumberRangeModal(true)}
                      className="m-2 w-4/5 rounded-lg p-2 text-white text-center border-2 border-secondary hover:border-secondary-light hover:bg-secondary shadow-lg disabled:invisible"
                      disabled={!markers.length}
                    >
                      Add Number Range
                    </button>
                    <button
                      className="m-2 w-4/5 rounded-lg p-2 text-white text-center border-2 border-secondary border-sedondary hover:border-secondary-light hover:bg-secondary shadow-lg disabled:invisible"
                      aria-details="save button"
                      onClick={handleModal}
                      disabled={!markers.length}
                    >
                      Save Walk
                    </button>
                    <button
                      className="m-2 w-4/5 rounded-lg p-2 text-white text-center border-2 border-secondary border-sedondary hover:border-secondary-light hover:bg-secondary shadow-lg disabled:hidden"
                      name="delete"
                      aria-details="save button"
                      onClick={handleModal}
                      disabled={!currentWalk}
                    >
                      Delete Walk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showPopupModal && (
        <PopupModal isOpen={showPopupModal} onCancel={() => setShowPopupModal(false)}>
          <div className="w-full flex flex-wrap sm:rounded-lg m-4 p-4">
            <LocationUsersTable locationId={currentLocation.id || 0} />
          </div>
        </PopupModal>
      )}
      <ConfirmationModal isOpen={showConfirmationModal} title={`Overwrite ${currentWalk?.name}?`} message={`This will overwrite waypoints for ${currentWalk?.name}.`} saveStatus={savingWalkStatus} onConfirm={handleSaveWalk} onCancel={() => setShowConfirmationModal(false)} />

      <ConfirmationModal isOpen={showDeleteConfirmationModal} title={`Delete ${currentWalk?.name}?`} message={`This will delete ${currentWalk?.name} and it's waypoints.`} saveStatus={deleteStatus} onConfirm={handleDeleteWalk} onCancel={() => setShowDeleteConfirmationModal(false)} />
      <NewWalkModal
        isOpen={showNewWalkModal}
        title={`${currentLocation.name}`}
        message={"Give this walk a name and brief description."}
        onConfirm={handleSaveWalk}
        onCancel={() => setShowNewWalkModal(false)}
        handleWalkName={handleWalkName}
        handleWalkDescription={handleWalkDescription}
        walkName={walkName}
        walkDescription={walkDescription}
        status={savingWalkStatus}
      />{" "}
      <PopupModal
        isOpen={showNumberRangeModal}
        onCancel={() => {
          console.log("Cancel button clicked - closing modal")
          setShowNumberRangeModal(false);
          setIsProcessingMarker(false);
          setNumberRangeInput("");
          setSelectedMarker(null);
        }}
        className="w-1/3 max-w-lg"
        hideCloseButton={true}
      >
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-4">Enter Number Range</h3>
          <p className="text-sm text-gray-600 mb-4">
            Enter unit numbers separated by commas, dashes, or spaces (e.g., "101-105, 107, 109-112")
          </p>
          <textarea
            value={numberRangeInput}
            onChange={handleNumberRangeInputChange}
            className="w-full p-2 border rounded-lg mb-4 h-24"
            placeholder="101-105, 107, 109-112"
          />
          <div className="flex justify-end gap-2">
            <button
              onClick={() => {
                console.log("Cancel button clicked in modal footer")
                setShowNumberRangeModal(false);
                setIsProcessingMarker(false);
                setNumberRangeInput("");
                setSelectedMarker(null);
              }}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                console.log("Add Units button clicked")
                // First, close the modal immediately
                setShowNumberRangeModal(false);
                console.log("Modal closed, processing updates...")

                // Then process the updates
                if (selectedMarker) {
                  console.log("Updating existing marker:", selectedMarker)
                  const updatedMarkers = markers.map(marker => {
                    if (marker.label === selectedMarker.label) {
                      return {
                        ...marker,
                        unitNumberRange: numberRangeInput,
                        hasUnitRange: true,
                        isCompleted: true
                      };
                    }
                    return marker;
                  });
                  setMarkers(updatedMarkers);
                } else {
                  console.log("Creating new marker with range:", numberRangeInput)
                  const nextLabel = markers.length + 1;
                  const newMarker = {
                    label: nextLabel,
                    unitNumberRange: numberRangeInput,
                    hasUnitRange: true,
                    isCompleted: true
                  };
                  handleMarkers([newMarker]);
                }

                // Finally, reset all states
                console.log("Resetting states...")
                setIsProcessingMarker(false);
                setNumberRangeInput("");
                setSelectedMarker(null);
              }}
              disabled={!numberRangeInput.trim()}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Add Units
            </button>
          </div>
        </div>
      </PopupModal>
    </div>
  )
}
