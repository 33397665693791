import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { incidentService } from '../../services/IncidentService'
import Table from './common/Table'
import { useDeviceView } from '../../hooks/useDeviceView'
import { TABLET, LARGE_TABLET, LARGE_DESKTOP } from '../../utils/screenSize'
import { Incident } from '../../Interfaces'
import Switch from "@mui/material/Switch"
import IncidentModal from '../modals/IncidentModal'

const truncateText = (text: string, maxLength: number) => {
  if (!text) return '';
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

export default function IncidentsTable() {
  const [incidents, setIncidents] = useState<Incident[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState("")
  const [showUnresolvedOnly, setShowUnresolvedOnly] = useState(true)
  const deviceView = useDeviceView()
  const navigate = useNavigate()
  const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    fetchIncidents()
  }, [searchTerm, showUnresolvedOnly])

  const fetchIncidents = async () => {
    try {
      setIsLoading(true)
      const data = await incidentService.getAll()
      const filteredData = showUnresolvedOnly
        ? data.filter((incident: Incident) => !incident.resolvedAt)
        : data
      setIncidents(filteredData)
    } catch (error) {
      console.error("Error fetching incidents:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const headers = [
    { label: "Created", key: "createdAt" },
    { label: "Location", key: "locationName" },
    { label: "Priority", key: "priority" },
    { label: "Type", key: "type" },
    { label: "First Note", key: "oldestNote" },
    { label: "Latest Note", key: "latestNote", hide: true },
    { label: "Status", key: "resolvedAt" },
    { label: "Images", key: "imagesCount" }
  ]

  if (deviceView >= TABLET) {
    headers.unshift({ label: "ID", key: "id" })
  }

  if (deviceView >= LARGE_TABLET) {
    headers.push({ label: "Notes", key: "notesCount" })
  }

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const handleIncidentClick = (incident: Incident) => {
    setSelectedIncident(incident)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedIncident(null)
  }

  const handleIncidentUpdate = (updatedIncident: Incident) => {
    fetchIncidents();
    setIsModalOpen(false);
    setSelectedIncident(null);
  }

  const getPriorityText = (typeId: number) => {
    switch (typeId) {
      case 1:
        return 'Critical';
      case 2:
        return 'Important';
      case 3:
        return 'Regular';
      default:
        return '';
    }
  };

  const getPriorityPill = (priority: string) => {
    switch (priority) {
      case 'Critical':
        return <span className="px-3 py-1 bg-red-100 text-red-800 rounded-full text-sm font-medium">Critical</span>;
      case 'Important':
        return <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm font-medium">Important</span>;
      case 'Regular':
        return <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">Regular</span>;
      default:
        return null;
    }
  };

  const renderIncidentType = (incidentTexts: any[]) => {
    if (!incidentTexts?.length) return '';
    return (
      <div className="flex gap-2 flex-wrap">
        {incidentTexts.map((text) => (
          <span key={text.id} className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
            {text.incidentNoteText.text}
          </span>
        ))}
      </div>
    );
  };

  if (isLoading) return null;

  const processedIncidents = incidents.map(incident => {
    const sortedNotes = [...incident.notes].sort((a, b) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    const hasMultipleNotes = sortedNotes.length > 1;
    const priorityText = getPriorityText(incident.typeId);

    return {
      ...incident,
      createdAt: new Date(incident.createdAt).toLocaleDateString(),
      locationName: incident.walk?.location?.name || 'Unknown Location',
      resolvedAt: incident.resolvedAt ? 'Resolved' : 'Unresolved',
      notesCount: incident.notes?.length || 0,
      imagesCount: incident.images?.length || 0,
      oldestNote: truncateText(sortedNotes[0]?.note || '', 25),
      latestNote: hasMultipleNotes ? truncateText(sortedNotes[sortedNotes.length - 1]?.note || '', 25) : '',
      priorityText: priorityText
    };
  });

  return (
    <>
      <Table
        data={processedIncidents.map(incident => ({
          ...incident,
          priority: getPriorityPill(incident.priorityText),
          type: renderIncidentType(incident.incidentTexts)
        }))}
        title="Incidents"
        handleSearch={handleSearch}
        headers={headers}
        extraHeaderContent={
          <div className="flex items-center">
            <span className="text-gray-600 mr-2">Show Unresolved Only</span>
            <Switch
              checked={showUnresolvedOnly}
              onChange={(e) => setShowUnresolvedOnly(e.target.checked)}
              color="primary"
            />
          </div>
        }
        onRowClick={(incident) => {
          // Strip out React elements before passing to click handler
          const { priority, type, ...cleanIncident } = incident;
          handleIncidentClick(cleanIncident);
        }}
      />
      <IncidentModal
        incident={selectedIncident}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onUpdate={handleIncidentUpdate}
      />
    </>
  );
} 