import React, { useEffect, useRef } from "react";

interface PopupModalProps {
  isOpen: boolean
  children: React.ReactNode
  title?: string
  message?: string
  onCancel: (e: { preventDefault: () => void }) => void
  className?: string
  hideCloseButton?: boolean
}

export default function PopupModal({ isOpen, children, title = "", message = "", onCancel, className = "", hideCloseButton = false }: PopupModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel({ preventDefault: () => { } });
      }
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onCancel({ preventDefault: () => { } });
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onCancel]);

  if (!isOpen) return null;

  // Wrap the children in a click handler that prevents event bubbling
  const wrappedChildren = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        onClick: (e: React.MouseEvent<HTMLElement>) => {
          // Call the original onClick if it exists
          if (child.props.onClick) {
            child.props.onClick(e);
          }
          // Prevent event bubbling
          e.stopPropagation();
        }
      });
    }
    return child;
  });

  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto`} onClick={() => onCancel({ preventDefault: () => { } })}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          ref={modalRef}
          className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-3/4 ${className}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            {title && <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>}
            {message && <p className="mt-2 text-sm text-gray-500">{message}</p>}
            {wrappedChildren}
          </div>
          {!hideCloseButton && (
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCancel({ preventDefault: () => { } });
                }}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
