import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Children } from "../../Interfaces"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { Suspense, useContext, useEffect } from "react"
import { navigation } from "../../utils/helper"
import LoadingSpinTrail from "../../components/loading/LoadingSpinTrail"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "../../pages/errors/ErrorFallback"
import DataContext from "../../context/dataProvider"
import { useQuery } from "@tanstack/react-query"
import useUser from "../../hooks/useUser"
import { userService } from "../../services/userService"
import Footer from "../../components/footer"
import { authService } from "../../services/authService"

export default function MainLayout() {
  navigation.navigate = useNavigate()
  navigation.location = useLocation()
  const navigate = useNavigate()
  const user = useUser()
  const { setCurrentUser } = useContext(DataContext)

  // Redirect to login if no token or invalid user
  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token || user.id === 0) {
      authService.logout()
      navigate("/login", { replace: true })
    }
  }, [user.id, navigate])

  const { data: userData, error, isLoading } = useQuery(
    ["user", user.id],
    () => {
      const token = localStorage.getItem("token")
      if (!token || user.id === 0) {
        throw new Error("No valid token or user")
      }
      return userService.getOne(user.id)
    },
    {
      retry: false,
      enabled: user.id > 0 && !!localStorage.getItem("token"),
      onError: (error: Error) => {
        console.error("Error fetching user data:", error)
        if (error.message === "Invalid token" || error.message === "No token available" || error.message === "No valid token or user") {
          authService.logout()
          navigate("/login", { replace: true })
        }
      }
    }
  )

  useEffect(() => {
    if (userData) {
      setCurrentUser(userData)
    }
  }, [userData, setCurrentUser])

  // Show loading state while fetching user data
  if (isLoading) {
    return <LoadingSpinTrail />
  }

  // Handle error state
  if (error) {
    return <ErrorFallback error={error} resetErrorBoundary={() => {
      authService.logout()
      navigate("/login", { replace: true })
    }} />
  }

  return (
    <div className="flex w-full max-h-full">
      <Navbar />
      <Sidebar />
      <div className="flex w-full justify-center z-0 mt-12 bg-secondary-light max-h-screen overflow-y-auto">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Suspense fallback={<LoadingSpinTrail />}>
            <div className="flex flex-col items-center w-full md:p-6 justify-between" style={{}}>
              <Outlet />
              <Footer />
            </div>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  )
}
